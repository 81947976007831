import {useState} from 'react';
import './App.css';

const visualAids = [
  {href: "/cc-adcs.jpg"},
  {href: "/cc-maple.png"},
  {href: "/cc-baby.png"},
];

const testimonials = [
  "His kindness and strong communication skills also make him a great teammate and i miss working closely with him!",
  "And, of course, he was instrumental in helping build the original previsit product that launched at Advanced Derm ",
  "Chris is not only super smart and hard-working, but super chill. What a great trifecta",
  "Chris shows up to pretty much every incident troubleshooting session without being asked, contributes great ideas, and always helps with the solution. He's a super-powered, always-available backup in the toughest situations!",
  "...did a sick Fall Out Boy rendition at karaoke.",
  "He also is incredibly smart and thorough and very dedicated to product quality.",
  "Chris is a very thorough, helpful code reviewer. i’ve learned a lot from his suggestions — he has brought up different ways of tackling the same problem that I did not think of, and I appreciate his attention to detail and willingness to help.",
  "I interviewed Chris, and was very impressed by his smart, insightful questions from day 1, which obviously kept coming after he joined Cedar.",
]

function App() {

  const [clicks, setClicks] = useState(0);
  const getMarquees = (num) => {
    let parrots = []
    for (let i=0; i < num; i++) {
      const scrollSpeed = (Math.random() + .1) * 75
      const direction = Math.random() < .5 ? 'left' : 'right';
      const width = Math.random() * 200;
      parrots.push(<marquee style={{width: "100%"}} direction={direction} scrollamount={scrollSpeed}><img src="/pp.gif" width={width}/></marquee>);
    }
    return parrots;
  }

  return (
    <div className="App">
      <header className="App-header" onClick={() => setClicks(clicks + 1)}>
        {clicks > 3 && getMarquees(7)}
        {clicks > 0 && <img src={visualAids[0].href} style={{position: "absolute", left: "10vw", top: "10vh", width: "20vw"}} />}
        {clicks > 1 && <img src={visualAids[2].href} style={{position: "absolute", left: "30vw", top: "60vh", width: "50vw"}} />}
        {clicks > 2  && <img src={visualAids[1].href} style={{position: "absolute", left: "70vw", top: "20vh", width: "20vw"}} />}
        {clicks > 4 && (<div className="testimonials">
          {testimonials.map((t, i) => {
            return (
              <div key={i}>
                {getMarquees(1)}
                <p className="testimonial" style={{float: i % 2 === 0 ? 'left' : 'right'}}>{t}</p>
                {getMarquees(1)}
              </div>
            )
          })}
        </div>)}
        {clicks > 5 && <p>Thank you for all you do, Chris!</p>}
        {clicks > 8 && getMarquees(10)}
      </header>
    </div>
  );
}

export default App;
